import "./Footer.css";
import { FaTwitter, FaTelegramPlane, FaFileAlt } from "react-icons/fa"; // Import icons

export const Footer = () => {
  return (
    <div className="footer-container">
      <div className="social-links">
        <a
          href="https://x.com/ether_bros"
          target="_blank"
          rel="noreferrer"
          className="social-link"
        >
          <FaTwitter className="social-icon" />
          Twitter
        </a>
        <a
          href="https://t.me/ether_bros"
          target="_blank"
          rel="noreferrer"
          className="social-link"
        >
          <FaTelegramPlane className="social-icon" />
          Telegram
        </a>
        <a href="/whitepaper" className="social-link">
          <FaFileAlt className="social-icon" />
          Docs
        </a>
      </div>

      <div className="copyright">
        Copyright © {new Date().getFullYear()}{" "}
        <a
          href="https://t.me/ether_bros"
          target="_blank"
          rel="noreferrer"
          className="copyright-link"
        >
          Ether bros
        </a>
      </div>
    </div>
  );
};

export default Footer;
