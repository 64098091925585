import { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/database";
import "./Leaderboard.css"; // Assuming you have a CSS file for styling
import { useSelector } from "react-redux";
import { Footer } from "../molecules/Footer/Footer";

const Leaderboard = () => {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [userRank, setUserRank] = useState(null);
  const [userScore, setUserScore] = useState(null);
  const userAddress = useSelector((state) => state.engine.address);

  useEffect(() => {
    const fetchLeaderboardData = async () => {
      const scoresRef = firebase.database().ref("scores");

      // Listen for changes in the scores data
      scoresRef.on("value", (snapshot) => {
        const data = snapshot.val();
        const leaderboardArray = [];

        for (const address in data) {
          leaderboardArray.push({
            address,
            score: data[address],
          });
        }

        // Sort by score in descending order (highest scores first)
        leaderboardArray.sort((a, b) => b.score - a.score);

        // Find the user's rank and score
        const userIndex = leaderboardArray.findIndex(
          (entry) => entry.address === userAddress
        );
        console.log(userAddress);
        if (userIndex !== -1) {
          setUserRank(userIndex + 1);
          setUserScore(leaderboardArray[userIndex].score);
        }

        setLeaderboardData(leaderboardArray);
      });
    };

    fetchLeaderboardData();
  }, [userAddress]);

  const handleRetweetClick = () => {
    const tweetText = `I'm ranked #${userRank} with a score of ${userScore} in the EthersBros game! Come play and see if you can beat me: https://etherbros.xyz`;
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      tweetText
    )}`;

    window.open(twitterUrl, "_blank");
  };

  return (
    <>
      <div className="leaderboard-container">
        <h2>Leaderboard</h2>

        {userRank !== null && userScore !== null ? (
          <div className="user-info">
            <p>
              Your Score: <strong>{userScore}</strong> | Your Rank:{" "}
              <strong>#{userRank}</strong> | You're eligible for the claim!
            </p>
            <button className="retweet-button" onClick={handleRetweetClick}>
              Retweet your rank!
            </button>
          </div>
        ) : (
          <p>Loading your rank...</p>
        )}

        <table className="leaderboard-table">
          <thead>
            <tr>
              <th>Rank</th>
              <th>Address</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {leaderboardData.map((entry, index) => (
              <tr key={entry.address}>
                <td>{index + 1}</td>
                <td>{entry.address}</td>
                <td>{entry.score}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default Leaderboard;
