import "./Title.css";

// assets
import Mario from "../../../assets/img/mario.png";
import Ether from "../../../assets/img/logo.png";
const Title = () => {
  return (
    <div className="title-container">
      <a href="/">
        <img src={Mario} alt="" className="mario-logo" />
      </a>
      <a href="/">
        <img src={Ether} alt="" className="ether-logo" width={270} />
      </a>
      <a style={{ color: "white", textDecoration: "none" }} href="leaderboard">
        Leaderboard
      </a>
    </div>
  );
};
export default Title;
