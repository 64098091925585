import { useEffect, useState } from "react";
import MarioCharacter from "../../../assets/img/mario.png";
import "./LoadingScreen.css";
import {
  setLoadingScreen,
  setAddress,
} from "../../../config/redux/engineSlice";
import { useDispatch } from "react-redux";
import Web3 from "web3";

const LoadingScreen = () => {
  const [isReady, setIsReady] = useState(false);
  const [isWrongNetwork, setIsWrongNetwork] = useState(false);
  const [networkChecked, setNetworkChecked] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkWalletConnection = async () => {
      if (window.ethereum && !networkChecked) {
        try {
          const web3 = new Web3(window.ethereum);
          const accounts = await web3.eth.getAccounts();

          if (accounts.length > 0) {
            const address = accounts[0];
            dispatch(setAddress(address));

            // Check network using chainId instead of net.getId() for faster and more reliable results
            const chainId = await window.ethereum.request({
              method: "eth_chainId",
            });

            if (chainId !== "0x1") {
              // '0x1' is Ethereum Mainnet
              setIsWrongNetwork(true);
            } else {
              setIsWrongNetwork(false);
              dispatch(setLoadingScreen(false)); // Only hide loading screen if on correct network
            }
          }
        } catch (error) {
          console.error("Error checking wallet connection: ", error);
        }

        // Mark network as checked to prevent unnecessary rechecks
        setNetworkChecked(true);
      } else {
        console.error("MetaMask not available.");
      }
    };

    checkWalletConnection();

    setTimeout(() => {
      setIsReady(true);
    }, 5000);
  }, [dispatch, networkChecked]);

  const handleConnectClick = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const web3 = new Web3(window.ethereum);
        const accounts = await web3.eth.getAccounts();
        const address = accounts[0];

        const chainId = await window.ethereum.request({
          method: "eth_chainId",
        });

        if (chainId === "0x1") {
          dispatch(setAddress(address));
          setIsWrongNetwork(false);
          dispatch(setLoadingScreen(false));
        } else {
          setIsWrongNetwork(true);
        }
      } catch (error) {
        console.error("Error connecting to MetaMask:", error);
      }
    } else {
      console.error("MetaMask not available.");
    }
  };

  const switchToEthereum = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(1) }], // Ethereum Mainnet ID
        });
        // After successful switch, recheck network and hide loading screen
        setIsWrongNetwork(false);
        dispatch(setLoadingScreen(false));
      } catch (error) {
        if (error.code === 4001) {
          // User rejected the request
          console.error("User rejected the request to switch networks.");
        } else {
          console.error("Error switching to Ethereum network:", error);
        }
      }
    }
  };

  return (
    <div className="loading-screen-container">
      <img src={MarioCharacter} alt="" className="loading-mario" />
      {!isReady && <h1 className="loading-title">Loading...</h1>}

      {isReady && isWrongNetwork && (
        <div className="input-container">
          <p className="network-error">
            You're connected to the wrong network.
          </p>
          <button className="connect-button" onClick={switchToEthereum}>
            Switch to Ethereum Mainnet
          </button>
        </div>
      )}

      {isReady && !isWrongNetwork && (
        <div className="input-container">
          <button className="connect-button" onClick={handleConnectClick}>
            Connect with MetaMask
          </button>
        </div>
      )}
    </div>
  );
};

export default LoadingScreen;
