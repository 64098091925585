import React from "react";
import "./WhitepaperPage.css";
import { Footer } from "../molecules";

const WhitepaperPage = () => {
  return (
    <>
      <div className="whitepaper-container">
        <h1>Whitepaper</h1>

        <section className="whitepaper-section">
          <p>
            <strong>ETHER BROS</strong> is an innovative decentralized gaming
            platform built on the Ethereum blockchain. The flagship game is a
            skill-based platformer that allows players to connect their wallets,
            play, and compete for rewards in ETH. With the game already live,
            ETHER BROS merges the nostalgia of classic platformers with the
            decentralized nature of blockchain technology, offering players an
            opportunity to turn their gaming achievements into tangible rewards.
          </p>
        </section>

        <section className="whitepaper-section">
          <h2>Gameplay & Rewards</h2>
          <p>
            Players compete on the ETHER BROS leaderboard, where the top
            performers are eligible for a share of an ETH prize pool. This pool
            will become active shortly after the launch of the $BROS token. The
            rewards structure is as follows:
          </p>
          <ul>
            <li>
              50% of the ETH prize pool will be airdropped to the player with
              the highest score on the leaderboard.
            </li>
            <li>
              The remaining 50% will be distributed among the 9 other top
              players on the leaderboard.
            </li>
          </ul>
        </section>

        <section className="whitepaper-section">
          <h2>The $BROS Token</h2>
          <p>
            $BROS is the native token of the ETHER BROS ecosystem and will be
            fairlaunched on Uniswap, with no seed round and no presale. It will
            serve as both the governance and incentive token for the broader
            ETHER BROS platform. Token holders will have the ability to shape
            the future of the ecosystem through governance voting and will be
            eligible for rewards as more games are developed.
          </p>
          <p>
            <strong>Revenue Sharing:</strong> A tax on the volume of $BROS
            transactions will directly fund the ETH prize pool for the
            top-performing players. This ensures an ongoing cycle of incentives
            for gamers and token holders alike.
          </p>
        </section>

        <section className="whitepaper-section">
          <h2>Roadmap</h2>
          <p>
            The ETHER BROS platformer is just the beginning. As part of our
            commitment to building a decentralized gaming ecosystem, the team
            has plans to release multiple games in the near future. Each new
            game will integrate with the broader ETHER BROS ecosystem, offering
            additional opportunities for players to earn and compete.
          </p>
        </section>

        <section className="whitepaper-section">
          <h2>Join Us</h2>
          <p>
            To stay updated on the upcoming $BROS token launch and find out when
            ETH rewards will be claimable for players, join our official
            <a href="https://t.me/ether_bros" target="_blank" rel="noreferrer">
              Telegram community
            </a>
            . This is your gateway to exclusive news, updates, and announcements
            related to the future of ETHER BROS.
          </p>
        </section>
      </div>
    </>
  );
};

export default WhitepaperPage;
