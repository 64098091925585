import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../../App.css";
import { useSelector } from "react-redux";
import {
  Birds,
  Bricks,
  Clouds,
  Mario,
  Obstacles,
  Sun,
  KeyMessage,
  LoadingScreen,
  Score,
  MobileControls,
  Footer,
} from "../../components";
import Leaderboard from "../../components/Leaderboard/leaderboard";
import WhitepaperPage from "../../components/Whitepaper/WhitepaperPage";

function Home() {
  const isPlay = useSelector((state) => state.engine.play);
  const isLoading = useSelector((state) => state.engine.loadingScreen); // Moved isLoading inside Home

  return (
    <>
      {isLoading && <LoadingScreen />} {/* Loading screen for Home only */}
      <div className={`App ${isLoading ? "blur-background" : ""}`}>
        {" "}
        {/* Optional: Add blur effect */}
        {!isPlay && <KeyMessage />}
        <Bricks />
        <Mario />
        <Sun />
        <Clouds />
        <Birds />
        <Obstacles />
        <Score />
      </div>
      <MobileControls />
      <Footer />
    </>
  );
}

function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/leaderboard" element={<Leaderboard />} />
        <Route path="/whitepaper" element={<WhitepaperPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
