import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setScore, setLastScore } from "../../../config/redux/engineSlice";
import "./Score.css";
import firebase from "firebase/app";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBg521owBL3hK0df436XCxAOUDB29f6MSs",
  authDomain: "ethersbro.firebaseapp.com",
  projectId: "ethersbro",
  storageBucket: "ethersbro.appspot.com",
  messagingSenderId: "337581485540",
  appId: "1:337581485540:web:1e204bff972dc4b0796bf1",
};

firebase.initializeApp(firebaseConfig);
const db = firebase.database();

const Score = () => {
  const score = useSelector((state) => state.engine.score);
  const address = useSelector((state) => state.engine.address);
  const lastScore = useSelector((state) => state.engine.lastScore);
  const play = useSelector((state) => state.engine.play);
  const die = useSelector((state) => state.engine.die);
  const dispatch = useDispatch();

  useEffect(() => {
    if (play && !die) {
      setTimeout(() => {
        dispatch(setScore(score + 1));
      }, 100);
    }

    console.log("playy", play);
    console.log("score", score);

    if (score && !play) {
      dispatch(setLastScore(score));

      const scoresRef = db.ref("scores");
      console.log(scoresRef);

      scoresRef.child(address).once("value", (snapshot) => {
        const existingLastScore = snapshot.val();
        console.log(address);

        if (existingLastScore === null || lastScore > existingLastScore) {
          scoresRef.child(address).set(lastScore);
        }
      });
    }
  }, [dispatch, play, score, lastScore, die, address]);

  return (
    <div
      className="score-container"
      style={{
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      {play && (
        <p className="score">
          Score: {score} {address}
        </p>
      )}
      {!play && (
        <p className="score">
          Score: {lastScore} {address}
        </p>
      )}
    </div>
  );
};

export default Score;
